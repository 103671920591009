import React, { Component } from "react";
import PropTypes from "prop-types";
import Pagination from "react-bootstrap/Pagination";

class Paginator extends Component {
  render() {
    let items = [];
    if (
      !this.props.totalPages ||
      !this.props.currentPage ||
      this.props.totalPages === 1
    ) {
      return null;
    }
    for (let i = 1; i <= this.props.totalPages; i++) {
      if (i === this.props.currentPage) {
        items.push(
          <Pagination.Item key={i} active>
            {i}
          </Pagination.Item>
        );
      } else {
        items.push(
          <Pagination.Item key={i} onClick={this.props.changePageFunc(i)}>
            {i}
          </Pagination.Item>
        );
      }
    }
    return <Pagination className="justify-content-center">{items}</Pagination>;
  }
}

Paginator.propTypes = {
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  changePageFunc: PropTypes.func.isRequired
};

export default Paginator;
