import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";
import { List } from "immutable";
import Entry from "./Entry.js";
import "./EntryList.css";

class EntryList extends Component {
  render() {
    return (
      <Row className="mt-3 mb-3">
        {this.props.entries.map((entry, index) => {
          if (~entry) {
            return (
              <Col xl={4} lg={4} md={6} className="pt-3 pb-3" key={index}>
                <Entry
                  data={entry}
                  user={this.props.user}
                  db={this.props.db}
                  bookmarks={this.props.bookmarks}
                  addBookmark={this.props.addBookmark}
                  deleteBookmark={this.props.deleteBookmark}
                />
              </Col>
            );
          } else {
            return (
              <Col xl={4} lg={4} md={6} className="pt-3 pb-3" key={index}>
                <Entry data={null} />
              </Col>
            );
          }
        })}
      </Row>
    );
  }
}

EntryList.propTypes = {
  entries: PropTypes.instanceOf(List)
};

export default EntryList;
