import React, { Component } from "react";
import { getMetadata } from "page-metadata-parser";
import { List, Map } from "immutable";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Container from "react-bootstrap/Container";
import DOMPurify from "dompurify";
import firebase from "@firebase/app";
import "@firebase/auth";
import "@firebase/analytics";
import "@firebase/firestore";
import withFirebaseAuth from "react-with-firebase-auth";

import "./App.css";
import EntryList from "./EntryList";
import Paginator from "./Paginator";
import firebaseConfig from "./firebaseConfig";

const printError = error => {
  console.log(error);
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();

const pageSize = 6;
class App extends Component {
  authStateChanged(user) {
    if (user) {
      // console.log(this.props.user.email);
      // console.log(this.props.user.uid);
      // this.props.user.getIdToken().then(idToken => {
      //   console.log(idToken);
      // });
      if (this.state.bookmarks === null) {
        db.collection("bookmark-lists")
          .doc(user.uid)
          .get()
          .then(doc => {
            if (doc.exists) {
              this.setState({ bookmarks: Map(doc.data()).delete("0") }, () =>
                console.log(`Read ${this.state.bookmarks.size} bookmarks.`)
              );
            } else {
              db.collection("bookmark-lists")
                .doc(user.uid)
                .set({ 0: Date.now() })
                .then(() =>
                  this.setState({ bookmarks: Map({}) }, () =>
                    console.log("Created new document.")
                  )
                )
                .catch(printError);
            }
          })
          .catch(printError);
      }
    } else {
      if (this.state.bookmarks !== null) {
        this.setState({ bookmarks: null });
      }
    }
  }

  constructor(props) {
    super(props);
    this.taskId = null;
    this.state = {
      listUrl: null,
      entries: List([]),
      IdList: List([]),
      page: null,
      bookmarks: null,
      viewBookmarks: false
    };
    // this.collectAndLoadList = this.collectAndLoadList.bind(this);
    this.loadPage = this.loadPage.bind(this);
    this.authStateChanged = this.authStateChanged.bind(this);
    firebase.auth().onAuthStateChanged(this.authStateChanged);
  }

  componentCleanup = () => {
    localStorage.setItem("listUrl", this.state.listUrl);
  };

  componentDidMount() {
    window.addEventListener("beforeunload", this.componentCleanup);
    const url = localStorage.getItem("listUrl");
    if (url && url !== "null") {
      this.collectAndLoadList(url)();
    }
  }

  componentWillUnmount() {
    this.componentCleanup();
    // remove the event handler for normal unmounting
    window.removeEventListener("beforeunload", this.componentCleanup);
  }

  async fetchGet(url) {
    const res = await fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer" // no-referrer, *client
    });
    if (!res.ok) {
      console.log(res);
      return null;
      // throw Error(res.statusText);
    }
    return res;
  }

  loadPage(page) {
    const taskId = Math.random() * 10000;
    // to deal with multiple commands at the same time
    this.taskId = taskId;
    const parser = new DOMParser();
    try {
      const itemList = this.state.IdList.slice(
        pageSize * (page - 1),
        pageSize * page
      );
      if (this.taskId !== taskId) return;
      this.setState({
        entries: List(new Array(itemList.length).fill(null)),
        page: page
      });
      itemList.map(async (itemId, index) => {
        const tmp = await this.fetchGet(
          `https://hacker-news.firebaseio.com/v0/item/${itemId}.json`
        );
        const item = await tmp.json();
        if (this.taskId !== taskId) return;
        this.setState({
          entries: this.state.entries.update(index, () =>
            Map({
              hnTitle: item.title,
              hnText: item.text
                ? DOMPurify.sanitize(item.text.replace("<p>", "<br/>"))
                : "",
              score: item.score,
              time: item.time,
              url: item.url,
              descendants: item.descendants,
              itemId
            })
          )
        });
        if (item.url) {
          // Using a CORS proxy
          const tmpHtml = await this.fetchGet(
            "https://cors.veritable.pw/" + item.url
          );
          if (tmpHtml != null) {
            const html = await tmpHtml.text();
            const doc = parser.parseFromString(html, "text/html");
            const metadata = getMetadata(doc, item.url);
            // console.log(metadata);
            if (this.taskId !== taskId) return;
            this.setState({
              entries: this.state.entries.update(index, orig =>
                orig.merge(
                  Map({
                    url: metadata.url,
                    image: metadata.image,
                    title: metadata.title,
                    desc: metadata.description
                  })
                )
              )
            });
          }
        }
        return null;
      });
    } catch (error) {
      console.log(error);
    }
  }

  handleChangePage = page => async e => {
    e.preventDefault();
    this.loadPage(page);
  };

  loadBookmarks = () => {
    firebase.analytics().logEvent("load_bookmark");
    console.log("loading bookmarks");
    if (this.state.bookmarks !== null) {
      this.setState(
        {
          IdList: List(
            this.state.bookmarks
              .toOrderedMap()
              .sort((a, b) => (b - a) / Math.abs(b - a))
              .keySeq()
              .toArray()
              .map(val => parseInt(val))
          ),
          viewBookmarks: true
        },
        () => {
          // console.log(this.state.IdList.toJS());
          this.loadPage(1);
        }
      );
    } else {
      this.setState({ IdList: List([]), viewBookmarks: true });
    }
  };

  collectAndLoadList = listUrl => async e => {
    const taskId = Math.random() * 10000;
    // to deal with multiple commands at the same time
    this.taskId = taskId;
    try {
      const res = await this.fetchGet(listUrl);
      const IdList = await res.json();
      if (this.taskId !== taskId) return;
      this.setState(
        {
          listUrl,
          IdList: List(IdList.slice(0, pageSize * 10)),
          viewBookmarks: false
        },
        () => this.loadPage(1)
      );
    } catch (error) {
      console.log(error);
    }
  };

  addBookmark = (itemId, timestamp) => {
    this.setState({
      bookmarks: this.state.bookmarks.set(itemId, timestamp)
    });
  };

  deleteBookmark = itemId => {
    this.setState({
      bookmarks: this.state.bookmarks.delete(itemId)
    });
  };

  render() {
    return (
      <React.Fragment>
        <center className="mt-5">
          <h1>Hacker News Reader</h1>
        </center>
        <Container fluid={false}>
          <Row className="justify-content-center">
            <Col xs={12} className="text-center">
              <ButtonToolbar className="text-center justify-content-center">
                {this.props.user ? (
                  <Button
                    className="mr-2 bold"
                    variant="info"
                    onClick={this.props.signOut}
                  >
                    Sign Out
                  </Button>
                ) : (
                  <Button
                    className="mr-2 bold"
                    variant="info"
                    onClick={this.props.signInWithGoogle}
                  >
                    Sign In
                  </Button>
                )}
                {this.props.user && this.state.viewBookmarks ? (
                  <Button
                    className="mr-2 bold"
                    variant="secondary"
                    onClick={this.collectAndLoadList(this.state.listUrl)}
                  >
                    Feeds
                  </Button>
                ) : (
                  <React.Fragment>
                    {this.props.user ? (
                      <Button
                        className="mr-2 bold"
                        variant="secondary"
                        onClick={this.loadBookmarks}
                      >
                        Bookmarks
                      </Button>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    <Button
                      className="mr-2 bold"
                      variant="primary"
                      onClick={this.collectAndLoadList(
                        "https://hacker-news.firebaseio.com/v0/topstories.json"
                      )}
                      disabled={
                        this.state.listUrl ===
                        "https://hacker-news.firebaseio.com/v0/topstories.json"
                      }
                    >
                      Top
                    </Button>
                    <Button
                      className="mr-2 bold"
                      variant="primary"
                      onClick={this.collectAndLoadList(
                        "https://hacker-news.firebaseio.com/v0/beststories.json"
                      )}
                      disabled={
                        this.state.listUrl ===
                        "https://hacker-news.firebaseio.com/v0/beststories.json"
                      }
                    >
                      Best
                    </Button>
                    <Button
                      className="mr-2 bold"
                      variant="primary"
                      onClick={this.collectAndLoadList(
                        "https://hacker-news.firebaseio.com/v0/showstories.json"
                      )}
                      disabled={
                        this.state.listUrl ===
                        "https://hacker-news.firebaseio.com/v0/showstories.json"
                      }
                    >
                      Show HN
                    </Button>
                    <Button
                      className="mr-2 bold"
                      variant="primary"
                      onClick={this.collectAndLoadList(
                        "https://hacker-news.firebaseio.com/v0/askstories.json"
                      )}
                      disabled={
                        this.state.listUrl ===
                        "https://hacker-news.firebaseio.com/v0/askstories.json"
                      }
                    >
                      Ask HN
                    </Button>
                    <Button
                      className="mr-2 bold"
                      variant="primary"
                      onClick={this.collectAndLoadList(
                        "https://hacker-news.firebaseio.com/v0/newstories.json"
                      )}
                      disabled={
                        this.state.listUrl ===
                        "https://hacker-news.firebaseio.com/v0/newstories.json"
                      }
                    >
                      New
                    </Button>
                  </React.Fragment>
                )}
              </ButtonToolbar>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col xs={12} className="text-center">
              <Paginator
                totalPages={Math.ceil(this.state.IdList.length / pageSize)}
                currentPage={this.state.page}
                changePageFunc={this.handleChangePage}
              />
            </Col>
          </Row>
          <EntryList
            entries={this.state.entries}
            page={this.state.page}
            user={this.props.user}
            db={db}
            bookmarks={this.state.bookmarks}
            addBookmark={this.addBookmark}
            deleteBookmark={this.deleteBookmark}
          />
          <Row className="justify-content-center">
            <Col xs={12} className="text-center">
              <Paginator
                totalPages={Math.ceil(this.state.IdList.size / pageSize)}
                currentPage={this.state.page}
                changePageFunc={this.handleChangePage}
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const firebaseAppAuth = firebaseApp.auth();

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider()
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth
})(App);
