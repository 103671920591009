import React, { Component } from "react";
import { Map } from "immutable";
import PropTypes from "prop-types";
import TimeAgo from "timeago-react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon
} from "react-share";
import firebase from "@firebase/app";
import "@firebase/firestore";
import Button from "react-bootstrap/Button";

const BookmarkButton = ({
  user,
  bookmarks,
  db,
  itemId,
  addBookmark,
  deleteBookmark
}) => {
  if (user && bookmarks !== null) {
    if (bookmarks.has(itemId)) {
      return (
        <Button
          variant="light"
          size="sm"
          onClick={() =>
            db
              .collection("bookmark-lists")
              .doc(user.uid)
              .update({
                [itemId]: firebase.firestore.FieldValue.delete()
              })
              .then(docRef => {
                console.log("Document updated.");
                deleteBookmark(itemId);
              })
              .catch(function(error) {
                console.error("Error adding document: ", error);
              })
          }
        >
          Bookmarked
        </Button>
      );
    } else {
      const timestamp = Date.now();
      return (
        <Button
          variant="dark"
          size="sm"
          onClick={() => {
            if (bookmarks.size > 120) {
              alert("Too many bookmarks! Remove some first.");
            } else {
              db.collection("bookmark-lists")
                .doc(user.uid)
                .set(
                  {
                    [itemId]: timestamp
                  },
                  { merge: true }
                )
                .then(docRef => {
                  console.log("Document updated.");
                  addBookmark(itemId, timestamp);
                })
                .catch(function(error) {
                  console.error("Error adding document: ", error);
                });
            }
          }}
        >
          Bookmark
        </Button>
      );
    }
  } else {
    return <span></span>;
  }
};
class Entry extends Component {
  render() {
    const placeholder_img = "https://picsum.photos/250/350/?blur";
    // console.log(this.props.picked.toJS());
    if (this.props.data) {
      const data = this.props.data.toJS();
      const hnUrl = `https://news.ycombinator.com/item?id=${data.itemId}`;
      let hostname = "";
      if (data.url) {
        hostname = new URL(data.url).hostname + " ";
      }
      if (data.hnText.length > 500) {
        data.hnText = data.hnText.slice(0, 500) + " (truncated...)";
      }
      if (data.title) {
        if (data.desc && data.desc.length > 500) {
          data.desc = data.desc.slice(0, 500) + " (truncated...)";
        }
        return (
          <div className="card h-100">
            <img
              className="card-img-top"
              src={data.image ? data.image : placeholder_img}
              alt="Cover"
            />
            <div className="card-body">
              <h4 className="card-title">
                [{data.score}]{" "}
                <a href={data.url} target="_blank" rel="noopener noreferrer">
                  {data.hnTitle}
                </a>{" "}
              </h4>
              <p className="card-text mb-1">
                <b>
                  <TimeAgo datetime={data.time * 1000} locale="en_US" />
                </b>{" "}
                {"\u00A0"}
                <i>{hostname}</i>
                <a href={hnUrl} target="_blank" rel="noopener noreferrer">
                  ({data.descendants} comments)
                </a>
              </p>
              <p className="card-text mt-1">
                <i>Page Title: {data.title}</i>
              </p>
              <p className="card-text-desc">{data.desc}</p>
              {(() => {
                if (data.hnText)
                  return (
                    <p className="card-text-desc">
                      <span
                        className="content"
                        dangerouslySetInnerHTML={{ __html: data.hnText }}
                      ></span>
                    </p>
                  );
                return <React.Fragment></React.Fragment>;
              })()}

              <span>
                <TwitterShareButton
                  url={data.url}
                  title={data.hnTitle}
                  className="mr-2"
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <TelegramShareButton
                  url={data.url}
                  title={data.hnTitle}
                  className="mr-2"
                >
                  <TelegramIcon size={32} round />
                </TelegramShareButton>
                <FacebookShareButton
                  url={data.url}
                  quote={data.hnTitle}
                  className="mr-2"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <BookmarkButton
                  itemId={data.itemId.toString()}
                  user={this.props.user}
                  db={this.props.db}
                  bookmarks={this.props.bookmarks}
                  addBookmark={this.props.addBookmark}
                  deleteBookmark={this.props.deleteBookmark}
                />
              </span>
            </div>
          </div>
        );
      } else {
        return (
          <div className="card h-100">
            <img className="card-img-top" src={placeholder_img} alt="Cover" />
            <div className="card-body">
              <h4 className="card-title">
                [{data.score}]{" "}
                {(() => {
                  if (data.url) {
                    return (
                      <a
                        href={data.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {data.hnTitle}
                      </a>
                    );
                  } else {
                    return data.hnTitle;
                  }
                })()}
              </h4>
              <p className="card-text">
                <b>
                  <TimeAgo datetime={data.time * 1000} locale="en_US" />
                </b>{" "}
                {"\u00A0"}
                <i>{hostname}</i>
                <a href={hnUrl} target="_blank" rel="noopener noreferrer">
                  ({data.descendants} comments)
                </a>
              </p>
              <p className="card-text-desc">
                <span
                  className="content"
                  dangerouslySetInnerHTML={{ __html: data.hnText }}
                ></span>
              </p>
              <span>
                <TwitterShareButton
                  url={data.url || hnUrl}
                  title={data.hnTitle}
                  className="mr-2"
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <TelegramShareButton
                  url={data.url || hnUrl}
                  title={data.hnTitle}
                  className="mr-2"
                >
                  <TelegramIcon size={32} round />
                </TelegramShareButton>
                <FacebookShareButton
                  url={data.url || hnUrl}
                  quote={data.hnTitle}
                  className="mr-2"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <BookmarkButton
                  itemId={data.itemId.toString()}
                  user={this.props.user}
                  db={this.props.db}
                  bookmarks={this.props.bookmarks}
                  addBookmark={this.props.addBookmark}
                  deleteBookmark={this.props.deleteBookmark}
                />
              </span>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="card h-100">
          <img className="card-img-top" src={placeholder_img} alt="Cover" />
          <div className="card-body">
            <h4 className="card-title">Loading</h4>
            <p className="card-text"></p>
          </div>
        </div>
      );
    }
  }
}

Entry.propTypes = {
  data: PropTypes.instanceOf(Map)
};

export default Entry;
