export default {
  apiKey: "AIzaSyCit2WG5YVAWJ_Ow93scidBc9_YIyS4meY",
  authDomain: "hackernews-browser.firebaseapp.com",
  databaseURL: "https://hackernews-browser.firebaseio.com",
  projectId: "hackernews-browser",
  storageBucket: "hackernews-browser.appspot.com",
  messagingSenderId: "564440897195",
  appId: "1:564440897195:web:6df212a40bb8763d74c9ee",
  measurementId: "G-BDZHF4JG91"
};
